/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { Instagram, LinkedIn, Phone, Skype } from 'pages/homepage/contact-us/svg';
import Envelope from 'svg/envelope';
import Container from 'components/container';
import HStack from 'components/hstack';
import VStack from 'components/vstack';
import styles from './footer.module.scss';

import worldImage from '../../pages/landing/assets/world.png';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <Container>
                <Row gutter={[16, 32]} className={styles.middle_bar}>
                    <Col className={styles.column} span={24} sm={12} lg={7}>
                        <VStack className={styles.section}>
                            <h3>Follow us</h3>
                            <HStack className={[styles.links, styles.contact].join(' ')} style={{ gap: '1.25rem' }}>
                                <a href='#'>
                                    <Skype />
                                </a>
                                <a href='#'>
                                    <Phone />
                                </a>
                                <a href='#'>
                                    <Instagram />
                                </a>
                                <a href='#'>
                                    <LinkedIn />
                                </a>
                            </HStack>
                        </VStack>
                        <VStack className={styles.section}>
                            <HStack className={styles.contact} style={{ textTransform: 'lowercase' }}>
                                <Envelope /> <span>-</span> info@mernode.com
                            </HStack>
                        </VStack>
                    </Col>
                    <Col className={styles.column} span={24} sm={12} lg={7}>
                        <VStack className={styles.section}>
                            <h3> <img style={{ height: "21px", width: "21px" }} src={worldImage} alt="" />
                                &nbsp;
                                Our Locations
                            </h3>
                            <HStack style={{ gap: '1.25rem' }}>Istanbul – Turkey</HStack>
                            <HStack style={{ gap: '1.25rem' }}>Eskisehir – Turkey</HStack>
                            <HStack style={{ gap: '1.25rem' }}>Jersey City/NJ – USA</HStack>
                            <HStack style={{ gap: '1.25rem' }}>Dallas/TX – USA</HStack>
                        </VStack>
                    </Col>
                </Row>
                <HStack className={styles.top_bar}>
                    <HStack className={styles.icon_links}>
                        <HStack className={styles.group}>
                            <div>© 2022 Mernode® </div>
                            <Link to="legal/term-of-service">Terms of Service</Link>
                        </HStack>
                        <HStack className={styles.group}>
                            <Link to="legal/privacy-policy">Privacy Policy</Link>
                            <Link to="legal/term-of-use">Term of Use</Link>
                        </HStack>
                    </HStack>
                </HStack>
            </Container>
        </div>
    );
};

export default Footer;

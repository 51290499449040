import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import Header from 'components/header';
import Footer from 'components/footer';
import VStack from 'components/vstack';
import GoTop from 'components/go-top';

import './App.css';

// pages
const Landing = lazy(() => import('pages/landing'));
const Aboutus = lazy(() => import('pages/aboutus'));
const ContactUs = lazy(() => import('pages/contact-us'));
const UnderConstruction = lazy(() => import('pages/under-construction'));
const HowToHire = lazy(() => import('pages/how-to-hire'));
const HowToFindWork = lazy(() => import('pages/how-to-find-work/how-to-find-work'));

function App() {
    return (
        <VStack className='App'>
            <Header />
            <div id='app-main-content'>
                <Suspense fallback={<div />}>
                    <Routes>
                        <Route path='' element={<Landing />} />
                        <Route path='about-us' element={<Aboutus />} />
                        <Route path='contact-us' element={<ContactUs />} />
                        <Route path='how-to-hire' element={<HowToHire />} />
                        <Route path='how-to-find-work' element={<HowToFindWork />} />
                        <Route path='*' element={<UnderConstruction />} />
                    </Routes>
                </Suspense>
            </div>
            <GoTop />
            <Footer />
        </VStack>
    );
}

export default App;

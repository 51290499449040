import React, { useEffect, useState } from 'react';

import { UpOutlined } from '@ant-design/icons';
import styles from './go-top.module.scss';

const GoTop = () => {
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        let scrollTop;
        document.querySelector('.App').addEventListener('scroll', (e) => {
            if (scrollTop > e.target.scrollTop) {
                if (e.target.scrollTop < 350) {
                    setVisibility(false);
                    return;
                }
                setVisibility(true);
            } else setVisibility(false);

            scrollTop = e.target.scrollTop;
        });
    }, []);

    return (
        <div
            className={styles.go_to_top}
            style={visibility ? { opacity: 1, pointerEvents: 'visible' } : { opacity: 0, pointerEvents: 'none' }}
            onClick={() => document.querySelector('.App').scrollTo({ top: 0, behavior: 'smooth' })}
        >
            <UpOutlined />
        </div>
    );
};

export default GoTop;

import React from 'react';

const Envelope = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='16' viewBox='0 0 18 16' fill='none'>
            <path
                d='M17.3571 0.924805H0.642857C0.287277 0.924805 0 1.21208 0 1.56766V14.4248C0 14.7804 0.287277 15.0677 0.642857 15.0677H17.3571C17.7127 15.0677 18 14.7804 18 14.4248V1.56766C18 1.21208 17.7127 0.924805 17.3571 0.924805ZM16.5536 3.1507V13.6212H1.44643V3.1507L0.891964 2.71878L1.68147 1.70427L2.54129 2.37324H15.4607L16.3205 1.70427L17.11 2.71878L16.5536 3.1507ZM15.4607 2.37123L9 7.39356L2.53929 2.37123L1.67946 1.70226L0.889955 2.71677L1.44442 3.14869L8.30692 8.4844C8.50428 8.63773 8.74707 8.72096 8.99699 8.72096C9.2469 8.72096 9.4897 8.63773 9.68705 8.4844L16.5536 3.1507L17.108 2.71878L16.3185 1.70427L15.4607 2.37123Z'
                fill='#434343'
            />
        </svg>
    );
};

export default Envelope;

import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import Container from 'components/container/container';

// components
import {
    Button,
    HStack,
    VStack,
    Spacer
} from '@avena/components';

// styles
import Logo from 'svg/logo';
import styles from './header.module.scss';

const Header = ({ children, title, titlePosition = 'left', buttons = [], onBackClick, ...props }) => {
    const { pathname } = useLocation();

    const [isMenuActive, setIsMenuActive] = useState(false);
    const url = pathname === '/' ? 'https://company.mernode.co' : 'https://talent.mernode.co';
    return (
        <>
            <header className={styles.header}>
                <Container>
                    <div className={styles.header_content}>
                        <div className={styles.logo}>
                            <div style={{
                                fontSize: "44px",
                                color: "#0066CC",
                                fontWeight: "bold",
                                textTransform: "uppercase"
                            }}>
                                Mernode
                            </div>
                        </div>
                    </div>
                </Container>
            </header>
        </>
    );
};

export default Header;
